import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import Hero from "../components/hero"
import SEO from "../components/seo"
import Navbar from "../components/Navbar/Navbar"
import "../styles/subteams.css"


const Wrapper = styled.div`
    display: grid;
    grid-auto-rows: minmax(300px, auto);
    grid-gap: 4em;
    color: #F5F5F7;
    font-family: 'Roboto';
    position: relative;
    text-align: center;
    top: 300px;
`
const SubteamsPage = () => (
  <Layout>
    <SEO title="Subteams" />
    <Navbar />
    <Wrapper>
        <h1> SUBTEAMS </h1>
        <br />
        Avionics: The Avionics team designs and builds the hardware on which our programming runs. Sensors, power regulation, and handling actuation are all the purview of Avionics.
        <br />
        Programming: The Programming team creates and maintains the software that gives commands to our systems, and that allows us to receive and visualize data from our systems.
        <br />
        Propulsion: The Propulsion team researches, designs, and builds the systems that allow our rocket to move.
        <br />
        Structures: The Structures team researches, designs, and builds the load-bearing components of our test stand and our rocket.
        <br />
    </Wrapper>
  </Layout>
)

export default SubteamsPage


/*
export default function SubteamsPage() {
    return <div>
      <Layout>
        <SEO title="Subteams" />
        <Navbar />
        <Hero />
        <Wrapper>
            Subteams
        </Wrapper>
      </Layout>
    </div>
}
*/

/*
  return <div style={{ color: 'tomato' }}>
  <h1>Welcome to Subteams Page</h1>
  <Link to="/">Back</Link>
  </div>
*/
